import type { ProductCategory } from "~/lib/models";

export const CATEGORIES: ProductCategory[] = [
    {
        title: "Alimentação",
        route: "/products/category/Alimentação",
        imgUrl: "/images/category/food.svg",
    },
    {
        title: "Anestésicos",
        route: "/products/category/Anestésicos",
        imgUrl: "/images/category/anesthetics.svg",
    },
    {
        title: "Desparasitantes",
        route: "/products/category/Desparasitantes",
        imgUrl: "/images/category/dewormers.svg",
    },
    {
        title: "Vacinas",
        route: "/products/category/Vacinas",
        imgUrl: "/images/category/vaccines.svg",
    },
    {
        title: "Suplementos",
        route: "/products/category/Suplementos",
        imgUrl: "/images/category/supplements.svg",
    },
];
